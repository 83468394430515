import { $themeBreakpoints } from '@themeConfig'
import VueCookies from 'vue-cookies'
import jwt from 'jsonwebtoken'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    userData: 
      jwt.decode($cookies.get('jwt')) ? {
        "id": jwt.decode($cookies.get('jwt'))?.serial,
        "fullName": jwt.decode($cookies.get('jwt'))?.name,
        "username": jwt.decode($cookies.get('jwt'))?.sub,
        "expires": jwt.decode($cookies.get('jwt'))?.exp,
        "avatar":"/img/sft_logo.09bcaab0.png",
        "email": jwt.decode($cookies.get('jwt'))?.sub,
        "scope": jwt.decode($cookies.get('jwt'))?.scope,
        "role": jwt.decode($cookies.get('jwt'))?.name === "Vasilis Pallidis Insurance Brokers" ? "admin" :"client",
        "ability": [{"action":"manage","subject":"all"}],
        "extras": {"eCommerceCartItemsCount":0}
      } : null
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    USER_DATA(state, val) {
      state.userData = val
    }
  },
  actions: {},
}
