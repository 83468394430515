import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, isTokenExpired, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import landing from './routes/landing'
import home from './routes/home'
import adminpanel from './routes/adminpanel'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import terms from './routes/terms'
import privacy from './routes/privacy'
import about from './routes/aboutus'
import contact from './routes/contact'
import affiliate from './routes/affiliate'
import payments from './routes/payments'
import summmary from './routes/summary'
import information from './routes/information'
import offers from './routes/offers'
import sales from './routes/sales'
import profile from './routes/profile'
import logout from './routes/logout'
import customerDetails from './routes/customerDetails'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'landing' } },
    ...apps,
    ...home,
    ...profile,
    ...landing,
    ...adminpanel,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...terms,
    ...privacy,
    ...about,
    ...contact,
    ...affiliate,
    ...payments,
    ...offers,
    ...sales,
    ...summmary,
    ...information,
    ...logout,
    ...customerDetails,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  // logout if token expired
  isTokenExpired()
  
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  if (localStorage.getItem("checkedProducts") !== null) {
    localStorage.removeItem("checkedProducts");
  }
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
