export default [
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/sft/Logout.vue'),
      beforeEnter: (to, from) => {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem("memberDts")
        localStorage.removeItem("quote_info")
        localStorage.removeItem("quote_info_app")
        localStorage.removeItem("checkedProducts")
        localStorage.removeItem("adminPanel")
        // Remove cookies
        window.$cookies.remove("jwt")
        location.reload()
      },
    },
  ]